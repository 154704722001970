<template>
	<div class="texture-background-beige">
		<div class="header-image">
			<h1 class="head-image-text">About NLSE</h1>
		</div>

		<div class="context-container">
			<h2 class="context-title">WHO IS NLSE?</h2>
			<div class="line"></div>
			<p class="context-desc">{{ about_us_description }}</p>

			<h2 class="context-title">DIVERSITY, EQUITY & INCLUSION</h2>
			<div class="line"></div>

			<p class="context-desc">{{ about_us_diversity }}</p>
			<!-- <h2 class="context-title">LEADERSHIP TEAM</h2>
			<div class="line"></div>

			<div class="job-title">
				<b-row>
					<b-col
						v-for="about_us_name in about_us_names"
						:key="about_us_name.id"
						cols="12"
						sm="6"
						md="4"
						lg="3"
					>
						<div class="leader-item">
							<div class="team-continer">
								<div class="flexbox-container">
									<h3 class="job-title-text">{{ about_us_name.name }}</h3>
									<span class="divider" />
									<p class="leader-item-text">{{ about_us_name.title }}</p>
								</div>
							</div>
						</div>
					</b-col>
				</b-row>
			</div> -->
		</div>
	</div>
</template>

<script>
import { getCompanyInformation } from "../../../services/company";
export default {
	data() {
		return {
			about_us_description: "",
			about_us_diversity: "",
			about_us_names: [],
		};
	},
	mounted() {
		const { whoWeAre, diversity, leadershipTeam } = getCompanyInformation();
		this.about_us_description = whoWeAre;
		this.about_us_diversity = diversity;
		this.about_us_names = leadershipTeam;
	},
};
</script>

<style lang="scss" scoped>
@import "../../../views/FrontendPages/CorporateInfoPages/corporate_info.css";

.header-image {
	background-image: url("../../../assets/images/background/corporateinfopage/AdobeStock_283336669_colorized_blue@2x.png");
	background-position: right;
}

@media (max-width: 768px) {
	.header-image {
		background-image: url("../../../assets/images/background/corporateinfopage/AdobeStock_283336669_colorized_blue.png") !important;
	}

	.job-title-text {
		font-size: 1.25rem !important;
	}

	.sub-job-title-text {
		font-size: 1.2rem !important;
	}
}
</style>
